<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>{{ title }} Customer</h5>
          </div>
          <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="onSubmit" autocomplete="off">
              <div class="ibox-content">
                <div class="row">
                  <div class="form-group col-md-4">
                    <ValidationProvider
                      name="Customer Name"
                      :rules="'required|alpha_num'"
                      v-slot="{ errors, classes }"
                    >
                      <label>Name *</label>
                      <input
                        type="text"
                        placeholder="Customer Name"
                        v-model="customer.name"
                        class="form-control"
                        :class="classes"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Description *</label>
                    <ValidationProvider
                      name="Customer Description"
                      :rules="{ required: true }"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        placeholder="Customer Description"
                        v-model="customer.description"
                        class="form-control"
                        :class="classes"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Authorization Method *</label>
                    <ValidationProvider
                      name="Authorization Method"
                      :rules="{ required: true }"
                      v-slot="{ errors, classes }"
                    >
                      <select
                        class="form-control"
                        v-model="customer.authMethod"
                        :class="classes"
                      >
                        <option value="">--- Selected ---</option>
                        <option value="DB_PWD">DB_PWD</option>
                        <option value="LDAP">LDAP</option>
                      </select>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-4 col-sm-offset-2">
                    <button class="btn btn-white btn-sm" type="button">Clear</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                      class="btn btn-primary btn-sm ladda-button"
                      data-style="zoom-in"
                      type="submit"
                      :disabled="invalid"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from "../plugin/AxiosService.js";
import Constant from "../plugin/Constant.js";
import Utils from "../plugin/Utils.js";
import AuditListner from "../plugin/AuditListner";

export default {
  data() {
    return {
      customer: {
        name: "",
        description: "",
        authMethod: "",
        id: 0,
      },
      title: Constant.data.ADD_TITLE,
      url: Constant.url.CUSTOMER_ADD,
    };
  },
  created: function () {
    this.loadCustomerData();
  },
  methods: {
    onSubmit: function () {
      const self = this;
      AxiosService.post(this.url, this.customer).then((result) => {
        if (
          Utils.hasValue(result, Constant.data.RESPONSE_CODE) &&
          result.responseCode == Constant.data.SUCCESS
        ) {
          AuditListner.addAuditLog(
            AuditListner.initalizedData(
              Constant.data.CUSTOMER_ACTIVITY,
              this.title,
              AuditListner.customerAuditTemplate(self.customer)
            )
          );
          self.$router.replace(Constant.url.SEPARATER + Constant.url.CUSTOMER_URI);
        }
      });
    },
    loadCustomerData: function () {
      var customerId = this.$route.query.id;
      const self = this;
      if (customerId != undefined) {
        AxiosService.get(Constant.url.CUSTOMER_GET + customerId).then((result) => {
          self.customer = result;
        });
        this.title = Constant.data.UPDATE_TITLE;
        this.url = Constant.url.CUSTOMER_UPDATE;
      }
    },
  },
};
</script>
<style scoped></style>

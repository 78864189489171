import { render, staticRenderFns } from "./AddCustomer.vue?vue&type=template&id=792da453&scoped=true"
import script from "./AddCustomer.vue?vue&type=script&lang=js"
export * from "./AddCustomer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "792da453",
  null
  
)

export default component.exports